import * as yup from 'yup';

import Button from '../atoms/Button';
import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useConsentManager } from '@lib/gdpr';
import { useContentElement } from '@plugins/next-cms-core';

export default function ChangeGdprConsentBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { changeConsent } = useConsentManager();

  // eslint-disable-next-line no-unused-vars
  useContentElement(
    data,
    ChangeGdprConsentBlock.dataSchema
  );

  const handleChangeConsent = () => {
    changeConsent();
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Button
          variant="contained"
          onClick={handleChangeConsent}
        >
          Cookie-Banner anpassen
        </Button>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

ChangeGdprConsentBlock.typeName = 'ComponentContentContentBlockChangeGdprConsent'; // Strapi element type
ChangeGdprConsentBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired
};
ChangeGdprConsentBlock.dataSchema = yup.object().shape({

});
ChangeGdprConsentBlock.graphQlSchema = `
... on ${ChangeGdprConsentBlock.typeName} {
  id
}
`;
