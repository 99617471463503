import AccordionSliderBlock from './components/contentTypes/AccordionSliderBlock';
import AppointmentRequestFormBlock from './components/contentTypes/AppointmentRequestFormBlock';
import ChangeGdprConsentBlock from './components/contentTypes/ChangeGdprConsentBlock';
import CustomerTestimonialsBlock from './components/contentTypes/CustomerTestimonialsBlock';
import EmbedBlock from './components/contentTypes/EmbedBlock';
import FaqBlock from './components/contentTypes/FaqBlock';
import HeroBlock from './components/contentTypes/HeroBlock';
import InterestCalculatorBlock from './components/contentTypes/InterestCalculatorBlock';
import InterestRateDevelopmentBlock from './components/contentTypes/InterestRateDevelopmentBlock';
import MapBlock from './components/contentTypes/MapBlock';
import OnPageNavigationBlock from './components/contentTypes/OnPageNavigationBlock';
import OnPageNavigationTargetBlock from './components/contentTypes/OnPageNavigationTargetBlock';
import PageHeaderBlock from './components/contentTypes/PageHeaderBlock';
import PurchaseRentCalculatorBlock from './components/contentTypes/PurchaseRentCalculatorBlock';
import StaffBlock from './components/contentTypes/StaffBlock';
import TextBlock from './components/contentTypes/TextBlock';
import TextMediaBlock from './components/contentTypes/TextMediaBlock';
import TilesBlock from './components/contentTypes/TilesBlock';
import ContactFormBlock from './components/contentTypes/ContactFormBlock';

export const components = [
  AccordionSliderBlock,
  CustomerTestimonialsBlock,
  EmbedBlock,
  HeroBlock,
  MapBlock,
  OnPageNavigationBlock,
  OnPageNavigationTargetBlock,
  PageHeaderBlock,
  StaffBlock,
  TextBlock,
  TextMediaBlock,
  TilesBlock,
  FaqBlock,
  ChangeGdprConsentBlock,
  AppointmentRequestFormBlock,
  InterestRateDevelopmentBlock,
  InterestCalculatorBlock,
  PurchaseRentCalculatorBlock,
  ContactFormBlock
];
