import * as yup from 'yup';

import { Box, Grid } from '@material-ui/core';
import { ButtonElement, ColorScheme, TypographyElement, useContentElement } from '@plugins/next-cms-core';

import Container from '../atoms/Container';
import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';

export default function HeroBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    HeroBlock.dataSchema
  );

  const { buttons, color_scheme } = elementData;
  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semantic_variant: 'h3',
    display_variant: 'h3'
  });

  const text = omitBy(elementData.text, isNull);
  defaults(text, {
    semantic_variant: 'div',
    display_variant: 'body1',
    text_align: 'justify'
  });

  const colorScheme = ColorScheme.getColorScheme(color_scheme, 'secondary');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box mb={text.value ? 3 : 0}>
            <TypographyElement
              data={title}
              color={MUI_COLOR_MAPPING[colorScheme]}
              className={classes.title}
            />
          </Box>
          {text.value && (
            <Grid
              container
              justify="center"
              className={classes.textContainer}
            >
              <Grid item xs={12} md={10} lg={9}>
                <TypographyElement
                  data={text}
                  color={MUI_COLOR_MAPPING[colorScheme]}
                />
              </Grid>
            </Grid>
          )}
          {buttons && buttons.length > 0 && (
            <Box mt={3}>
              <Grid
                container
                justify="center"
                spacing={3}
              >
                {buttons.map(button => (
                  <Grid
                    key={button.id}
                    item
                    xs={12}
                    md={'auto'}
                  >
                    <ButtonElement
                      fullWidth
                      data={button}
                      variant="outlined"
                      component="a"
                      color={MUI_COLOR_MAPPING[colorScheme]}
                      size="large"
                      endIcon={<FiArrowRight />}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden'
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main
  },
  rootLight: {
    backgroundColor: theme.palette.light.main
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  textContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9)
    }
  },
  title: {
    '& p': {
      margin: 0
    }
  }
}));

HeroBlock.typeName = 'ComponentContentContentBlockHero'; // Strapi element type
HeroBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    color_scheme: ColorScheme.propTypes
  }).isRequired
};
HeroBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  text: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
  color_scheme: ColorScheme.dataSchema.nullable()
});
HeroBlock.graphQlSchema = `
... on ${HeroBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  color_scheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary'
};
