import * as yup from 'yup';

import { Box, Hidden, Typography } from '@material-ui/core';
import { LinkElement, MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { useRef, useState } from 'react';

import Container from '../atoms/Container';
import Image from '../atoms/Image';
import Link from '../atoms/Link';
import { NUMBER_OF_BANKS } from 'config';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';

const HEADER_HEIGHT = 140;
const HEADER_MAX_HEIGHT = 800;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function PageHeaderBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const currentIndex = useRef(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const { elementData } = useContentElement(
    data,
    PageHeaderBlock.dataSchema
  );

  const {
    pageHeaderSlides,
    enable_autoplay,
    enable_promotion_display
  } = elementData;
  let height = elementData.height;

  if (!height || height <= 0) {
    height = HEADER_MAX_HEIGHT;
  }

  const handleChangeSlide = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        index={slideIndex}
        onChangeIndex={handleChangeSlide}
        onSwitching={index => {
          setTimeout(() => {
            currentIndex.current = index;
          });
        }}
        enableMouseEvents
        autoplay={enable_autoplay}
        interval={5000}
        className={classes.slideRoot}
        style={{ maxHeight: height }}
      >
        {pageHeaderSlides.map(slide => (
          <Slide
            key={slide.id}
            slide={slide}
            height={height}
            classes={classes}
            currentIndex={currentIndex}
          />
        ))}
      </AutoPlaySwipeableViews>
      {enable_promotion_display && (
        <>
          <Hidden smDown>
            <div className={classes.promotionRoot}>
              <Link href="/ueber-uns">
                <a>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.promotionDisplay}
                  >
                    <Box mr={2}>
                      <Image
                        src="/images/ImmoScout24-Experte-300x300.png"
                        alt="Immoscout24 Premium Partner"
                        isFluid
                        className={classes.promotionImage}
                      />
                    </Box>
                    <Box>
                      <Typography color="secondary" variant="caption">
                        Als Premium-Partner von
                        <br />
                        ImmoScout24
                        <br />
                        arbeiten wir mit
                        <br />
                        <strong>über {NUMBER_OF_BANKS} Banken</strong> zusammen.
                      </Typography>
                    </Box>
                  </Box>
                </a>
              </Link>
            </div>
          </Hidden>
          <Hidden mdUp>
            {pageHeaderSlides[slideIndex]?.disclaimer && (
              <div className={classes.disclaimerContainer}>
                <Container>
                  <TypographyElement
                    data={getDisclaimer(pageHeaderSlides[slideIndex]?.disclaimer)}
                  />
                </Container>
              </div>
            )}
            <Box my={1} alignSelf="stretch">
              <Container>
                <Link href="/ueber-uns">
                  <a>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Box mr={2}>
                        <Image
                          src="/images/ImmoScout24-Experte-300x300.png"
                          alt="Immoscout24 Premium Partner"
                          className={classes.promotionImage}
                        />
                      </Box>
                      <Box>
                        <Typography variant="caption">
                          Als Premium-Partner von ImmoScout24 arbeiten wir mit <strong>über {NUMBER_OF_BANKS} Banken</strong> zusammen.
                        </Typography>
                      </Box>
                    </Box>
                  </a>
                </Link>
              </Container>
            </Box>
          </Hidden>
        </>
      )}
    </div>
  );
}

function Slide({ height, slide, classes, currentIndex }) {
  const {
    title_position,
    title_background,
    link,
    media,
  } = slide;

  let title = null;
  let subtitle = null;
  let disclaimer = null;

  if (slide.title) {
    title = omitBy(slide.title, isNull);
    defaults(title, {
      semantic_variant: 'h1',
      display_variant: 'h1'
    });
  }

  if (slide.subtitle) {
    subtitle = omitBy(slide.subtitle, isNull);
    defaults(subtitle, {
      semantic_variant: 'h5',
      display_variant: 'h5'
    });
  }

  if (slide.disclaimer) {
    disclaimer = getDisclaimer(slide.disclaimer);
  }

  const handleClick = (e) => {
    if (currentIndex.current !== Math.round(currentIndex.current)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={classes.slideContainer}>
      <div className={classes.mediaContainer}>
        <MediaElement
          isAbsoluteFill
          data={media}
        />
      </div>
      <Container
        className={clsx(
          classes.contentContainer,
          classes[`contentPosition${title_position}`]
        )}
        style={{ maxHeight: height }}
      >
        {(title || subtitle) && (
          <div className={clsx(
            classes.titleContainer,
            classes[`titleBackground_${title_background ?? 'default'}`]
          )}>
            {subtitle && (
              <TypographyElement
                data={subtitle}
                color="secondary"
              />
            )}
            {title && (
              <TypographyElement
                data={title}
                color="secondary"
              />
            )}
          </div>
        )}
        {disclaimer && (
          <Hidden smDown>
            <div className={classes.disclaimerContainer}>
              <TypographyElement
                data={disclaimer}
                color="secondary"
              />
            </div>
          </Hidden>
        )}
      </Container>
      {link && (
        <LinkElement data={link} onClick={handleClick}>
          <a className={classes.link} />
        </LinkElement>
      )}
    </div>
  );
}

const CONTENT_CONTAINER_PADDING_FACTOR = 5;
const CONTENT_MAX_WIDTH = 2000;
const heightStyleMobile = 'calc(80vh - 45px)';
const heightStyleDesktop = 'calc(100vh - 45px)';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  slideRoot: {
    width: '100%',
    maxWidth: CONTENT_MAX_WIDTH,
    height: heightStyleMobile,
    [theme.breakpoints.up('lg')]: {
      height: heightStyleDesktop,
    }
  },
  slideContainer: {
    position: 'relative',
    flex: 1,
  },
  mediaContainer: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  contentContainer: {
    zIndex: 1,
    pointerEvents: 'none',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: heightStyleMobile,
    [theme.breakpoints.up('lg')]: {
      height: heightStyleDesktop,
    },
    paddingTop: HEADER_HEIGHT + theme.spacing(CONTENT_CONTAINER_PADDING_FACTOR),
    '& * > p': {
      margin: 0
    },
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover, &:focus': {
      background: 'rgba(255,255,255,0.15)'
    }
  },
  disclaimerContainer: {
    backdropFilter: 'saturate(180%) blur(3px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.35)',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
  },
  contentPositionTopLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  contentPositionTopCenter: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  contentPositionTopRight: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  contentPositionCenterLeft: {
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  contentPositionCenterCenter: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentPositionCenterRight: {
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  contentPositionBottomLeft: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  contentPositionBottomCenter: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  contentPositionBottomRight: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  promotionRoot: {
    pointerEvents: 'none',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    maxWidth: CONTENT_MAX_WIDTH,
    zIndex: 2,
    '& > a': {
      pointerEvents: 'all'
    }
  },
  promotionDisplay: {
    position: 'absolute',
    top: 160,
    left: theme.spacing(2),
  },
  promotionImage: {
    width: 120,
    height: 120,
    [theme.breakpoints.up('lg')]: {
      width: 140,
      height: 140
    }
  },
  titleContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  titleBackground_default: {},
  titleBackground_primary: {
    backgroundColor: theme.palette.primary.light
  },
  titleBackground_light: {
    backgroundColor: 'rgba(255,255,255,0.55)'
  },
  titleBackground_dark: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  },
}));

PageHeaderBlock.typeName = 'ComponentContentContentBlockPageHeader'; // Strapi element type
PageHeaderBlock.propTypes = {
  data: PropTypes.shape({
    height: PropTypes.number,
    enable_autoplay: PropTypes.bool.isRequired,
    enable_promotion_display: PropTypes.bool.isRequired,
    pageHeaderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title_position: PropTypes.string.isRequired,
      title_background: PropTypes.oneOf([
        null, 'default', 'primary', 'light', 'dark'
      ]),
      link: LinkElement.propTypes,
      media: MediaElement.propTypes,
      title: TypographyElement.propTypes,
      subtitle: TypographyElement.propTypes,
      disclaimer: TypographyElement.propTypes,
    }))
  }).isRequired
};
PageHeaderBlock.dataSchema = yup.object().shape({
  height: yup.number().nullable(),
  enable_autoplay: yup.bool(),
  enable_promotion_display: yup.bool(),
  pageHeaderSlides: yup.array().of(yup.object().shape({
    id: yup.string().required(),
    title_position: yup.string().required(),
    title_background: yup.string().nullable().oneOf([
      null, 'default', 'primary', 'light', 'dark'
    ]),
    link: LinkElement.dataSchema.nullable(),
    media: MediaElement.dataSchema,
    title: TypographyElement.dataSchema.nullable(),
    subtitle: TypographyElement.dataSchema.nullable(),
    disclaimer: TypographyElement.dataSchema.nullable(),
  }))
});
PageHeaderBlock.graphQlSchema = `
... on ${PageHeaderBlock.typeName} {
  id
  height
  enable_autoplay
  enable_promotion_display
  pageHeaderSlides: slides {
    id
    title_position
    title_background
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    title {
      ${TypographyElement.graphQlSchema}
    }
    subtitle {
      ${TypographyElement.graphQlSchema}
    }
    disclaimer {
      ${TypographyElement.graphQlSchema}
    }
  }
}
`;

function getDisclaimer(disclaimer) {
  disclaimer = omitBy(disclaimer, isNull);
  defaults(disclaimer, {
    semantic_variant: 'div',
    display_variant: 'caption'
  });
  return disclaimer;
}
