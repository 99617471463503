import * as yup from 'yup';

import { TypographyElement, useContentElement } from '@plugins/next-cms-core';

import AppointmentRequestForm from '../../forms/AppointmentRequestForm';
import { Box } from '@material-ui/core';
import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

export default function ContactFormBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const [hubSpotInstanceId, setHubSpotInstanceId] = useState(null);
  const { elementData } = useContentElement(
    data,
    ContactFormBlock.dataSchema
  );
  const { data: hubSpotInstanceData } = useQuery(FETCH_HUBSPOT_INSTANCE);

  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semantic_variant: 'h3',
    display_variant: 'h3'
  });

  useEffect(() => {
    if (hubSpotInstanceData?.hubSpotInstances.length > 0) {
      setHubSpotInstanceId(hubSpotInstanceData.hubSpotInstances[0].instanceId);
    }
  }, [hubSpotInstanceData?.hubSpotInstances.length]);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        {title && (
          <Box mb={5}>
            <TypographyElement
              data={title}
              align="center"
            />
          </Box>
        )}
        {hubSpotInstanceId && (
          <AppointmentRequestForm
            hubspotInstanceId={hubSpotInstanceId}
            formFields={{}}
          />
        )}
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

ContactFormBlock.typeName = 'ComponentContentContentBlockContactForm'; // Strapi element type
ContactFormBlock.propTypes = {
  data: PropTypes.shape({}).isRequired
};
ContactFormBlock.dataSchema = yup.object().shape({});
ContactFormBlock.graphQlSchema = `
... on ${ContactFormBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
}
`;

const FETCH_HUBSPOT_INSTANCE = gql`
  query HubSPotInstance {
    hubSpotInstances(where: { isDefault: true }) {
      instanceId
    }
  }
`;
