import * as yup from 'yup';

import { Button, Collapse, Divider, Hidden, List, ListItem, ListItemIcon, ListItemText, Paper, Toolbar } from '@material-ui/core';
import { FiArrowRight, FiChevronDown, FiChevronRight } from 'react-icons/fi';
import OnPageNavigationTargetBlock, { getAnchorRefName } from './OnPageNavigationTargetBlock';

import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';
import { useState } from 'react';

export default function OnPageNavigationBlock(props) {
  const { data, blocks } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    OnPageNavigationBlock.dataSchema
  );

  const { is_visible } = elementData;

  if (!is_visible) {
    return null;
  }

  const navigationTargets = blocks
    .filter(block => block.__typename === OnPageNavigationTargetBlock.typeName);

  return (
    <Hidden mdUp>
      <div className={classes.root}>
        <Container className={classes.container}>
          <Paper variant="outlined">
            <MobileMenuContainer items={navigationTargets} classes={classes} />
            {/*<Hidden smDown>
              <DesktopMenuContainer items={navigationTargets} classes={classes} />
            </Hidden>*/}
          </Paper>
        </Container>
      </div>
    </Hidden>
  );
}

function MobileMenuContainer({ items, classes }) {
  const [isExpanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(false);
  };

  return (
    <List disablePadding component="div">
      <ListItem button onClick={() => setExpanded(!isExpanded)}>
        <ListItemIcon>
          {isExpanded
            ? <FiChevronDown />
            : <FiChevronRight />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2'
          }}
        >
          Springe zu
        </ListItemText>
      </ListItem>
      <Collapse in={isExpanded}>
        <Divider />
        {items.map(({ id, label }) => (
          <ListItem
            key={id}
            component="a"
            href={`#${getAnchorRefName(id)}`}
            onClick={handleClick}
          >
            <ListItemIcon>
              <FiArrowRight />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2'
              }}
            >
              {label}
            </ListItemText>
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
}

function DesktopMenuContainer({ items, classes }) {
  const useSpacing = items.length >= 5;

  return (
    <Toolbar
      variant="dense"
      className={clsx({ [classes.toolbarItemSpacing]: useSpacing })}
    >
      {items.map(({ id, label }, index) => (
        <Button
          key={id}
          component="a"
          href={`#${getAnchorRefName(id)}`}
          startIcon={index === 0 ? <FiChevronRight /> : null}
        >
          {label}
        </Button>
      ))}
    </Toolbar>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  container: {

  },
  toolbarItemSpacing: {
    justifyContent: 'space-between'
  }
}));

OnPageNavigationBlock.typeName = 'ComponentContentContentBlockOnPageNavigation'; // Strapi element type
OnPageNavigationBlock.propTypes = {
  data: PropTypes.shape({
    is_visible: PropTypes.bool.isRequired
  }).isRequired,
  blocks: PropTypes.array.isRequired
};
OnPageNavigationBlock.dataSchema = yup.object().shape({
  is_visible: yup.bool().required()
});
OnPageNavigationBlock.graphQlSchema = `
... on ${OnPageNavigationBlock.typeName} {
  id
  is_visible
}
`;
