import * as yup from 'yup';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { ColorScheme, LinkElement, MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { animationDefaults, easings, timings } from '../../theme';

import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';

export default function TilesBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TilesBlock.dataSchema
  );

  const {
    columns,
    tiles,
    color_scheme
  } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semantic_variant: 'h3',
      display_variant: 'h3'
    });
  }

  let columnSpacing = 0;
  let columnSpan = Math.floor(12 / columns);

  if (tiles.length < 3) {
    columnSpacing = 10;
  } else if (tiles.length < 5) {
    columnSpacing = 7;
  } else {
    columnSpacing = 3;
  }

  const colorScheme = ColorScheme.getColorScheme(color_scheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Box>
        <Container className={classes.container}>
          {title && (
            <Box mb={5}>
              <TypographyElement data={title} align="center" />
            </Box>
          )}
          <Grid
            container
            spacing={columnSpacing}
            justify="center"
            alignItems="stretch"
          >
            {tiles.map(tile => (
              <Grid
                key={tile.id}
                item
                xs={12}
                sm={columnSpan}
                className={classes.tileContainer}
              >
                <Tile item={tile} classes={classes} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

function Tile(props) {
  const { classes, item } = props;

  const {
    display,
    text_over_media_position,
    text_over_media_color,
    bottom_price_badge
  } = item;

  const isMediaSvg = Boolean(item.media?.file.url.endsWith('.svg'));
  let text = null;
  let text_over_media = null;

  if (item.text && item.text.value) {
    text = omitBy(item.text, isNull);
    defaults(text, {
      semantic_variant: 'div',
      display_variant: 'body2',
      text_align: 'justify'
    });
  }

  if (item.text_over_media && item.text_over_media.value) {
    text_over_media = omitBy(item.text_over_media, isNull);
    defaults(text_over_media, {
      semantic_variant: 'div',
      display_variant: 'body2',
      text_align: 'justify'
    });
  }

  return (
    <Card elevation={3} className={clsx(classes.tile, {
      [classes.tileButton]: display === 'button'
    })}>
      <LinkElement data={item.link}>
        <CardActionArea className={classes.tileActionArea}>
          {display !== 'button'
            ? (
              <>
                {item.media && (
                  <div className={classes.tileMediaContainer}>
                    <MediaElement
                      isFluid
                      data={item.media}
                      className={classes.tileMedia}
                    />
                    {text_over_media && (
                      <div className={clsx(
                        classes.tileMediaTextContainer,
                        classes[`tileMediaTextContainer_${text_over_media_position ?? 'top'}`]
                      )}>
                        <TypographyElement
                          data={text_over_media}
                          color={text_over_media_color ?? 'initial'}
                          className={classes.tileMediaText}
                        />
                      </div>
                    )}
                  </div>
                )}
                {(item.title || text || item.button_label || bottom_price_badge) && (
                  <CardContent className={classes.tileContent}>
                    <Box flex={1}>
                      {item.title && (
                        <Box mb={2}>
                          <Typography variant="subtitle1">
                            {item.title}
                          </Typography>
                        </Box>
                      )}
                      {text && (
                        <Box mb={3}>
                          <TypographyElement
                            data={text}
                          />
                        </Box>
                      )}
                    </Box>
                    {item.button_label && (
                      <Button
                        fullWidth
                        component="a"
                        color="primary"
                        variant="outlined"
                      >
                        {item.button_label}
                      </Button>
                    )}
                  </CardContent>
                )}
                {bottom_price_badge && (
                  <CardActions>
                    <Box flex={1} p={1}>
                      <Box mb={1}>
                        <Divider />
                      </Box>
                      <Typography
                        color="primary"
                        variant="h4"
                        component="div"
                        align="right"
                        dangerouslySetInnerHTML={{
                          __html: bottom_price_badge
                        }}
                      />
                    </Box>
                  </CardActions>
                )}
              </>
            ) :
            (
              <>
                <CardContent
                  className={clsx(
                    classes.tileContent,
                    classes.tileContentCentered
                  )}
                >
                  {item.media && (
                    <MediaElement
                      isFluid={!isMediaSvg}
                      isMediaSvg={isMediaSvg}
                      data={item.media}
                      className={clsx(
                        classes.tileMedia,
                        classes.tileMediaSpacing,
                        isMediaSvg ? [
                          classes.tileMediaSvg,
                          classes[`tileMediaSvg_${item.media.scaling ?? 'normal'}`]
                        ] : null
                      )}
                    />
                  )}
                  {item.button_label && (
                    <Typography variant="button" align="center" color="secondary">
                      {item.button_label}
                    </Typography>
                  )}
                </CardContent>
              </>
            )}
        </CardActionArea>
      </LinkElement>
    </Card>
  );
}
Tile.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object
};

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main
  },
  rootLight: {
    backgroundColor: theme.palette.light.main
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main
  },
  container: {
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  tile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
    '&:hover': {
      transform: `scale(${animationDefaults.defaultScale})`
    }
  },
  tileMediaContainer: {
    position: 'relative'
  },
  tileMedia: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  tileMediaSpacing: {
    marginBottom: theme.spacing(2)
  },
  tileMediaSvg: {
  },
  tileMediaSvg_tiny: {
    width: 16,
    height: 16
  },
  tileMediaSvg_small: {
    width: 22,
    height: 22
  },
  tileMediaSvg_smaller: {
    width: 38,
    height: 38
  },
  tileMediaSvg_normal: {
    width: 60,
    height: 60
  },
  tileMediaSvg_larger: {
    width: 80,
    height: 80
  },
  tileMediaSvg_large: {
    width: 120,
    height: 120
  },
  tileMediaSvg_big: {
    width: 160,
    height: 160
  },
  tileMediaTextContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(1)
  },
  tileMediaTextContainer_top: {
    justifyContent: 'flex-start'
  },
  tileMediaTextContainer_center: {
    justifyContent: 'center'
  },
  tileMediaTextContainer_bottom: {
    justifyContent: 'flex-end'
  },
  tileButton: {
    backgroundColor: theme.palette.primary.main
  },
  tileActionArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textDecoration: 'none !important',
  },
  tileContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  tileContentCentered: {
    justifyContent: 'center',
    alignItems: 'center',
    //aspectRatio: '3 / 2', Error in layout
    minHeight: 150
  },
}));

TilesBlock.typeName = 'ComponentContentContentBlockTiles'; // Strapi element type
TilesBlock.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.number,
    title: TypographyElement.propTypes,
    tiles: PropTypes.arrayOf(PropTypes.shape({
      display: PropTypes.oneOf([null, 'button', 'card']),
      button_label: PropTypes.string,
      title: PropTypes.string,
      text: TypographyElement.propTypes,
      link: LinkElement.propTypes.isRequired,
      media: MediaElement.propTypes,
      text_over_media: TypographyElement.propTypes,
      text_over_media_position: PropTypes.string,
      text_over_media_color: PropTypes.string,
      bottom_price_badge: PropTypes.string
    })),
    color_scheme: ColorScheme.propTypes
  }).isRequired
};
TilesBlock.dataSchema = yup.object().shape({
  columns: yup.number().nullable(),
  title: TypographyElement.dataSchema.nullable(),
  tiles: yup.array().of(yup.object().shape({
    display: yup.string().oneOf([null, 'button', 'card']).nullable(),
    button_label: yup.string().nullable(),
    title: yup.string().nullable(),
    text: TypographyElement.dataSchema.nullable(),
    link: LinkElement.dataSchema.required(),
    media: MediaElement.dataSchema.nullable(),
    text_over_media: TypographyElement.dataSchema.nullable(),
    text_over_media_position: yup.string().nullable(),
    text_over_media_color: yup.string().nullable(),
    bottom_price_badge: TypographyElement.dataSchema.nullable(),
  })),
  color_scheme: ColorScheme.dataSchema.nullable()
});
TilesBlock.graphQlSchema = `
... on ${TilesBlock.typeName} {
  id
  columns
  title {
    ${TypographyElement.graphQlSchema}
  }
  tiles {
    id
    display
    button_label
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    text_over_media {
      ${TypographyElement.graphQlSchema}
    }
    text_over_media_position
    text_over_media_color
    bottom_price_badge
  }
  color_scheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
