import { Slider as MuiSlider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export default withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.contrastText,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 24px)',
    '& > span': {
      transform: 'none',
      borderRadius: theme.shape.borderRadius,
      width: 90,
    },
    '& > span > span': {
      transform: 'none',
      fontSize: theme.typography.h6.fontSize
    }
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(MuiSlider);
