import * as yup from 'yup';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { ButtonElement, MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';

import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';

export default function TextMediaBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TextMediaBlock.dataSchema
  );

  const variant = elementData.variant ?? 'Plain';
  const order = elementData.order ?? 'TextMedia';

  const items = [];
  const imageElement = {
    classes: [classes.mediaContainer],
    element: <ImageBlock variant={variant} data={elementData} classes={classes} />
  };
  const textElement = {
    classes: [classes.textContainer],
    element: <TextBlock variant={variant} data={elementData} classes={classes} />
  };
  const rootClasses = [classes.root];

  switch (order) {
    case 'MediaText':
      items.push(imageElement);
      items.push(textElement);
      rootClasses.push(classes.orderImageText);
      break;
    default:
      items.push(textElement);
      items.push(imageElement);
      rootClasses.push(classes.orderTextImage);
      break;
  }

  const columnSpan = variant === 'Card' ? 6 : 6;
  const columnSpacing = variant === 'Card' ? 0 : 10;

  if (variant === 'Card') {
    rootClasses.push(classes.variantCard);
  }

  return (
    <div className={clsx(rootClasses)}>
      <Container className={classes.container}>
        <Grid
          container
          alignItems="center"
          spacing={columnSpacing}
        >
          {items.map(({ element, classes }, index) => (
            <Grid
              key={index}
              item xs={12}
              md={columnSpan}
              className={clsx(classes)}
            >
              {element}
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

function ImageBlock(props) {
  const { classes, data } = props;
  const {
    media
  } = data;

  /*let mediaElement = null;

  switch(media.type){
    case 'video':
      mediaElement = (
        <video
          loop
          muted
          autoPlay
          className={classes.mediaTypeVideo}
        >
          <source src={media.url} type="video/mp4" />
        </video>
      );
      break;
    default:
      mediaElement = (
        <Image
          isFluid
          src={media.url}
        />
      );
      break;
  }*/

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mediaItem}
    >
      <MediaElement data={media} />
      {media.caption && (
        <div className={classes.mediaLabel}>
          <Typography variant="caption">
            {media.caption}
          </Typography>
        </div>
      )}
    </Box>
  );
}

function TextBlock(props) {
  const { variant, data, classes } = props;
  const { buttons } = data;

  const title = omitBy(data.title, isNull);
  defaults(title, {
    semantic_variant: 'h3',
    display_variant: 'h3'
  });

  const subtitle = omitBy(data.subtitle, isNull);
  defaults(subtitle, {
    semantic_variant: 'div',
    display_variant: 'body2'
  });

  const text = omitBy(data.text, isNull);
  defaults(text, {
    semantic_variant: 'div',
    display_variant: 'body1',
  });

  const content = (
    <>
      {subtitle?.value && (
        <Box mb={2}>
          <TypographyElement
            data={subtitle}
            color="primary"
            className={classes.textSubTitle}
          />
        </Box>
      )}
      {title?.value && (
        <Box mb={3}>
          <TypographyElement
            data={title}
            className={classes.textTitle}
          />
        </Box>
      )}
      {text?.value && (
        <Box>
          <TypographyElement data={text} />
          {buttons && (
            <Box mt={5}>
              <Grid container spacing={3}>
                {buttons.map((button) => (
                  <Grid item key={button.id} mx={1}>
                    <ButtonElement
                      data={button}
                      variant="contained"
                      component="a"
                      color="primary"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  if (variant === 'Card') {
    return (
      <Paper elevation={3}>
        <Box p={5}>
          {content}
        </Box>
      </Paper>
    );
  }

  return content;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  container: {
  },
  textContainer: {
    zIndex: 1,
  },
  mediaContainer: {
    zIndex: 0
  },
  mediaItem: {
    //position: 'relative',
  },
  mediaLabel: {
    flex: 1,
    marginTop: theme.spacing(0.5)
    //position: 'absolute',
    //bottom: `calc(${(theme.typography.caption.lineHeight) * -1}em - ${theme.spacing(0.5)}px)`,
  },
  mediaTypeVideo: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: 'auto',
  },
  orderImageText: {
    '& $mediaContainer': {
      transformOrigin: 'left center',
    },
    '& $mediaLabel': {
      left: theme.shape.borderRadius,
    }
  },
  orderTextImage: {
    '& $mediaContainer': {
      transformOrigin: 'right center',
    },
    '& $mediaLabel': {
    }
  },
  variantCard: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    '& $mediaContainer': {
      transform: 'scale(1.2)',
    },
    '& $mediaLabel': {
      maxWidth: '70%'
    }
  },
  textTitle: {
    '& p': {
      marginTop: 0
    }
  },
  textSubTitle: {
    '& p': {
      margin: 0
    }
  }
}));

const PROPTYPE_VARIANT = PropTypes.oneOf(['Plain', 'Card']);

TextMediaBlock.typeName = 'ComponentContentContentBlockTextMedia'; // Strapi element type
TextMediaBlock.propTypes = {
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    order: PropTypes.oneOf(['TextMedia', 'MediaText']),
    media: MediaElement.propTypes,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes))
  }).isRequired
};
TextMediaBlock.dataSchema = yup.object().shape({
  variant: yup.string().nullable().oneOf([null, 'Plain', 'Card']),
  order: yup.string().nullable().oneOf([null, 'TextMedia', 'MediaText']),
  media: MediaElement.dataSchema,
  title: TypographyElement.dataSchema,
  subtitle: TypographyElement.dataSchema,
  text: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable()
});
TextMediaBlock.graphQlSchema = `
... on ${TextMediaBlock.typeName} {
  id
  variant
  order
  media {
    ${MediaElement.graphQlSchema}
  }
  title {
    ${TypographyElement.graphQlSchema}
  }
  subtitle {
    ${TypographyElement.graphQlSchema}
  }
  text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
}
`;

TextBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
  }).isRequired
};
ImageBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PROPTYPE_VARIANT,
  data: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video'])
    })
  }).isRequired
};
