import * as yup from 'yup';

import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import PurchaseRentCalculator from '../organisms/PurchaseRentCalculator';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';

export default function PurchaseRentCalculatorBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    PurchaseRentCalculatorBlock.dataSchema
  );

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box mb={5}>
          <Typography variant="h3" gutterBottom align="center">
            Kauf-Miet-Rechner
          </Typography>
          <Typography align="center">
            Wie viel Eigenheim könnte ich mir alternativ leisten?
          </Typography>
        </Box>
        <PurchaseRentCalculator />
      </Container>
    </div >
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.quaternary.light
  },
  container: {

  },
  paper: {
    padding: theme.spacing(3)
  }
}));

PurchaseRentCalculatorBlock.typeName = 'ComponentContentContentBlockPurchaseRentCalculator'; // Strapi element type
PurchaseRentCalculatorBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired
};
PurchaseRentCalculatorBlock.dataSchema = yup.object().shape({
});
PurchaseRentCalculatorBlock.graphQlSchema = `
... on ${PurchaseRentCalculatorBlock.typeName} {
  id
}
`;
