import * as yup from 'yup';

import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography
} from '@material-ui/core';
import { MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import React, { useState } from 'react';

import Container from '../atoms/Container';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

export default function AccordionSliderBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { elementData } = useContentElement(
    data,
    AccordionSliderBlock.dataSchema
  );

  const { accordionSliderSlides } = elementData;

  if (!accordionSliderSlides || accordionSliderSlides.length === 0) {
    return null;
  }

  const currentSlide = accordionSliderSlides[expandedPanel];

  const handleExpandPanel = (index) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? index : 0);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} md={7}>
            <MediaElement
              data={currentSlide.media}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            {accordionSliderSlides.map((item, itemIndex) => (
              <ExpansionPanel
                key={itemIndex}
                expanded={expandedPanel === itemIndex}
                onChange={handleExpandPanel(itemIndex)}
              >
                <ExpansionPanelSummary
                  expandIcon={<FiChevronDown />}
                >
                  <Typography variant="subtitle2" component="h3">
                    {item.category}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box>
                    {item.title && (
                      <Box mb={3}>
                        <Typography
                          variant="h3"
                          className={classes.panelTitle}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
                    <TypographyElement data={item.text} />
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  panelTitle: {
    '& > p': {
      margin: 0
    }
  }
}));

AccordionSliderBlock.typeName = 'ComponentContentContentBlockAccordion'; // Strapi element type
AccordionSliderBlock.propTypes = {
  data: PropTypes.shape({
    accordionSliderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      category: PropTypes.string.isRequired,
      media: MediaElement.propTypes,
      text: TypographyElement.propTypes,
    }))
  }).isRequired
};
AccordionSliderBlock.dataSchema = yup.object().shape({
  accordionSliderSlides: yup.array().of(yup.object().shape({
    id: yup.number().required(),
    title: yup.string().nullable(),
    category: yup.string().required(),
    media: MediaElement.dataSchema.required(),
    text: TypographyElement.dataSchema,
  }))
});
AccordionSliderBlock.graphQlSchema = `
... on ${AccordionSliderBlock.typeName} {
  id
  accordionSliderSlides: slides {
    id
    media {
      ${MediaElement.graphQlSchema}
    }
    title
    category
    text {
      ${TypographyElement.graphQlSchema}
    }
  }
}
`;
