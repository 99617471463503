import * as yup from 'yup';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';

export default function OnPageNavigationTargetBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    OnPageNavigationTargetBlock.dataSchema
  );

  const { id, label } = elementData;

  return (
    <div className={classes.root}>
      <a name={getAnchorRefName(id)} title={label} />
    </div>
  );
}

export function getAnchorRefName(id) {
  return `onpage-nav-index-${id}`;
}

const useStyles = makeStyles(() => ({
  root: {
  },
}));

OnPageNavigationTargetBlock.typeName = 'ComponentContentContentBlockOnPageNavigationTarget'; // Strapi element type
OnPageNavigationTargetBlock.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired
};
OnPageNavigationTargetBlock.dataSchema = yup.object().shape({
  label: yup.string().required()
});
OnPageNavigationTargetBlock.graphQlSchema = `
... on ${OnPageNavigationTargetBlock.typeName} {
  id
  label
}
`;
