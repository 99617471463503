import * as yup from 'yup';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  FiChevronDown,
  FiClock,
  FiExternalLink,
  FiInfo,
  FiMail,
  FiMapPin,
  FiPhone
} from 'react-icons/fi';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/styles';
import {NUMBER_OF_BANKS} from 'config'
import Container from '../atoms/Container';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import ProvenExpertGoogleStars from '../organisms/ProvenExpertGoogleStars';
import getConfig from 'next/config';
import { useConsent } from '@lib/gdpr';
import { useContentElement } from '@plugins/next-cms-core';
import { useInView } from 'react-intersection-observer';

const { publicRuntimeConfig } = getConfig();

function MapBlockComponent(props) {
  const { data } = props;
  const theme = useTheme();
  const classes = useStyles();
  const hasConsent = useConsent('googleMaps');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ref, inView] = useInView();
  const { elementData } = useContentElement(
    data,
    MapBlock.dataSchema
  );
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState(
    isMobile
      ? DEFAULT_CENTER.mobile
      : DEFAULT_CENTER.desktop
  );
  // eslint-disable-next-line no-unused-vars
  const [selectedBranch, setSelectedBranch] = useState(null);

  // Additional data
  const { error, data: additionalData } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    throw error;
  }

  const {
    is_branch_overlay_visible,
    is_map_header_summary_visible
  } = elementData;
  const {
    branches
  } = additionalData ?? {
    branches: []
  };

  const onLoad = useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleBranchSelected = (id) => {
    let offsetLat = 0;
    let offsetLng = 0;

    if (!isMobile) {
      offsetLat = 0;
      offsetLng = -0.05;
    }

    const branch = branches.find(item => item.id === id);
    setZoom(DEFAULT_ZOOM_BRANCH);
    setCenter({
      lat: branch.geocode_lat + offsetLat,
      lng: branch.geocode_lng + offsetLng
    });
    setSelectedBranch(id);
  };
  const handleBranchDeselected = () => {
    setZoom(DEFAULT_ZOOM);
    setCenter(
      isMobile
        ? DEFAULT_CENTER.mobile
        : DEFAULT_CENTER.desktop
    );
    setSelectedBranch(null);
  };
  const handleClickBranch = (branch) => () => {
    openMap(`${branch.address_street} ${branch.address_zipcode}, ${branch.address_city}, ${branch.address_country}`);
  };

  return (
    <div ref={ref} className={classes.root}>
      {is_map_header_summary_visible && (
        <div className={classes.header}>
          <Container>
            <Box mb={3}>
              <Typography
                align="center"
                variant="h3"
                component="h3"
                color="secondary"
                className={classes.title}
              >
                Ihr Experte für Immobilien &amp; Finanzierungen
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Typography align="center" color="secondary" variant="body1">
                  Mehr als {NUMBER_OF_BANKS} Banken
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography align="center" color="secondary" variant="body1">
                  0-40 Jahre Zinsbindung
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography align="center" color="secondary" variant="body1">
                  Schnelle Kreditentscheidung
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography align="center" color="secondary" variant="body1">
                  Fördermittel &amp; Zuschüsse
                </Typography>
              </Grid>
            </Grid>
            {is_branch_overlay_visible && (
              <Hidden mdUp>
                <Box mt={5}>
                  <Branches
                    branches={branches}
                    classes={classes}
                    onBranchSelected={handleBranchSelected}
                    onBranchDeselected={handleBranchDeselected}
                  />
                </Box>
              </Hidden>
            )}
            <Box
              mt={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Paper>
                <Box px={5} py={1}>
                  <ProvenExpertGoogleStars />
                </Box>
              </Paper>
            </Box>
          </Container>
        </div>
      )}
      <div className={classes.container}>
        {hasConsent && (!map ? inView : true) && (
          <LoadScript
            googleMapsApiKey={publicRuntimeConfig.GOOGLE_API_KEY}
          >
            <GoogleMap
              mapContainerClassName={classes.mapContainer}
              zoom={zoom}
              center={center}
              options={mapOptions}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {branches.map(item => (
                <Marker
                  key={item.id}
                  position={{
                    lat: item.geocode_lat,
                    lng: item.geocode_lng
                  }}
                  label={{
                    text: item.name,
                    className: classes.markerLabel,
                    fontFamily: 'Mulish, sans-serif',
                    fontSize: '12px',
                    fontWeight: '700'
                  }}
                  onClick={handleClickBranch(item)}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        )}
        {is_branch_overlay_visible && (
          <Hidden smDown>
            <div className={classes.overlayContainer}>
              <Container>
                <Box my={5}>
                  <Grid container>
                    <Grid xs={6} item>
                      <Branches
                        branches={branches}
                        classes={classes}
                        onBranchSelected={handleBranchSelected}
                        onBranchDeselected={handleBranchDeselected}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </div>
          </Hidden>
        )}
      </div>
    </div>
  );
}

const MapBlock = memo(MapBlockComponent);
export default MapBlock;

function Branches(props) {
  const {
    branches,
    classes,
    onBranchSelected,
    onBranchDeselected
  } = props;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (branches.length === 0) {
      return;
    }

    const defaultExpandedBranch = branches.find(b => b.is_default_expanded_in_map);

    if (defaultExpandedBranch) {
      setExpanded(defaultExpandedBranch.id);
    }
  }, [branches.length]);
  useEffect(() => {
    expanded
      ? (onBranchSelected && onBranchSelected(expanded))
      : (onBranchDeselected && onBranchDeselected());
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return branches.map(branch => (
    <Accordion
      key={branch.id}
      expanded={expanded === branch.id}
      className={classes.branchItem}
      onChange={handleChange(branch.id)}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown />}
      >
        <Typography variant="body2">
          Standort
          {' '}
          <strong>{branch.name}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.branchItemDetailsContainer}>
        {branch.address_street && (
          <Box>
            <Grid
              container
              spacing={3}
              alignItems="center"
            >
              <Grid item xs={12} sm={'auto'} style={{ flex: 1 }}>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <FiMapPin className={classes.addressIcon} />
                  </Box>
                  <Typography variant="body2" component="div">
                    <strong>{branch.address_street}</strong>
                    {', '}
                    {branch.address_zipcode}
                    {' '}
                    {branch.address_city}
                  </Typography>
                </Box>
              </Grid>
              {branch.page_url && (
                <Grid item xs={12} sm={'auto'}>
                  <Link href={branch.page_url}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      component="a"
                      startIcon={<FiInfo />}
                    >
                      Standort
                    </Button>
                  </Link>
                </Grid>
              )}
              <Grid item xs={12} sm={'auto'}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<FiExternalLink />}
                  onClick={openRoute(
                    `${branch.address_street}, ${branch.address_zipcode} ${branch.address_city}, ${branch.address_country}`
                  )}
                >
                  Anfahrt
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box my={3}>
          <Divider />
        </Box>
        <Box>
          <Typography variant="h6">
            Öffnungszeiten
          </Typography>
          <BranchDepartment
            businessHours={branch.business_hours_sales}
            phone={branch.sales_phone}
            email={branch.primary_email}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  ));
}

Branches.propTypes = {
  branches: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  // Events
  onBranchSelected: PropTypes.func,
  onBranchDeselected: PropTypes.func,
};

function BranchDepartment({ businessHours, phone, email }) {
  const trimTime = time => time.substring(0, 5);

  return (
    <div>
      {businessHours.length > 0 && (
        <Box mt={1} mb={3}>
          <Box display="flex">
            <Box mr={2} mt={0.5}>
              <FiClock className="icon" />
            </Box>
            <Box flex={1}>
              {businessHours.map(item => (
                <Grid key={item.id} container>
                  <Grid item xs={4} md={2}>
                    {`${DAY_MAPPING[item.day_range_start]}.`}
                    {item.day_range_end && ` - ${DAY_MAPPING[item.day_range_end]}.`}
                  </Grid>
                  <Grid item xs={8} md={10}>
                    {trimTime(item.day_time_start)}
                    {item.day_time_end && ` - ${trimTime(item.day_time_end)}`}
                    {' Uhr'}
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Grid container spacing={3}>
        {email && (
          <Grid item xs={12} md={6}>
            <Link href={`mailto:${email}`}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                component="a"
                size="small"
                startIcon={<FiMail />}
              >
                {email}
              </Button>
            </Link>
          </Grid>
        )}
        {phone && (
          <Grid item xs={12} md={6}>
            <Link href={`tel:${phone}`}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                component="a"
                size="small"
                startIcon={<FiPhone />}
              >
                {phone}
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    height: '600px',
    position: 'relative',
    backgroundColor: theme.palette.tertiary.main,
  },
  header: {
    backgroundColor: theme.palette.tertiary.main,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  headerStrong: {
    fontWeight: theme.typography.fontWeightBold
  },
  title: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& span': {
      paddingRight: 15
    }
  },
  mapContainer: {
    width: '100%',
    height: '600px'
  },
  markerLabel: {
    backdropFilter: 'saturate(180%) blur(20px)',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    padding: '2px 7px 2px 8px'
  },
  overlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  branchItem: {
    pointerEvents: 'all'
  },
  branchItemDetailsContainer: {
    flexDirection: 'column'
  },
  addressIcon: {
    width: 20,
    height: 20,
    display: 'block'
  }
}));

MapBlock.typeName = 'ComponentContentContentBlockMap'; // Strapi element type
MapBlockComponent.propTypes = {
  data: PropTypes.shape({
    is_branch_overlay_visible: PropTypes.bool,
    is_map_header_summary_visible: PropTypes.bool,
  }).isRequired
};
MapBlock.dataSchema = yup.object().shape({
  is_branch_overlay_visible: yup.bool().nullable(),
  is_map_header_summary_visible: yup.bool().nullable(),
});
MapBlock.graphQlSchema = `
... on ${MapBlock.typeName} {
  id
  is_branch_overlay_visible
  is_map_header_summary_visible
}
`;

const FETCH_ADDITIONAL_DATA = gql`
query {
  branches(sort: "name:asc") {
    id
    branch_id
    is_default_expanded_in_map
    name
    address_street
    address_zipcode
    address_city
    address_country
    geocode_lat
    geocode_lng
    page_url
    primary_phone
    primary_email
    sales_phone
    workshop_phone
    business_hours_sales {
      id
      day_range_start
      day_range_end
      day_time_start
      day_time_end
    }
    business_hours_workshop {
      id
      day_range_start
      day_range_end
      day_time_start
      day_time_end
    }
  }
}
`;

function openMap(address) {
  const url = `http://maps.apple.com/?saddr=${encodeURIComponent(address)}`;
  const win = window.open(url, '_blank');
  win.focus();
}
function openRoute(destination) {
  return () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
}

const DAY_MAPPING = {
  Monday: 'Mo',
  Tuesday: 'Di',
  Wednesday: 'Mi',
  Thursday: 'Do',
  Friday: 'Fr',
  Saturday: 'Sa',
  Sunday: 'So',
};
const DEFAULT_ZOOM = 10;
const DEFAULT_ZOOM_BRANCH = 13;

const DEFAULT_CENTER = {
  desktop: {
    lat: 52.557443,
    lng: 13.055179
  },
  mobile: {
    lat: 52.557443,
    lng: 13.389179
  }
};

const mapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  styles: [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#bdbdbd'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dadada'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#cc3333'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#c9c9c9'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    }
  ]
};
