import * as yup from 'yup';

import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import { ButtonElement, MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';

import Container from '../atoms/Container';
import {
  FiInfo
} from 'react-icons/fi';
import Link from '../atoms/Link';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getConsultantUrl } from '../../constants';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import orderBy from 'lodash/orderBy';
import take from 'lodash/take';

export default function StaffBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    StaffBlock.dataSchema
  );

  const {
    max_visible_items,
    randomize_sort,
    sort_override,
    employees,
    buttons
  } = elementData;

  let title = null;
  let items = employees ?? [];

  if (sort_override) {
    const indexes = sort_override.split(',');
    items = items.map(x => ({
      ...x,
      index: 999
    }));

    indexes.forEach((id, i) => {
      var item = items.find(x => x.id === id);

      if(item)
        item.index = i;
    });

    items = orderBy(items, ['index']);
  }

  if (randomize_sort) {
    items = orderBy(items, ['random_index']);
  }

  if (max_visible_items > 0 && max_visible_items < items.length) {
    items = take(items, max_visible_items);
  }

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semantic_variant: 'h3',
      display_variant: 'h3',
      text_align: 'center'
    });
  }

  return (
    <div className={classes.root}>
      <Container>
        {title && (
          <Box mb={7}>
            <TypographyElement data={title} align="center"/>
          </Box>
        )}
        <Grid container spacing={5} justify="center">
          {items.map(item => (
            <Grid key={item.id} item xs={12} sm={6} md={3}>
              <Employee item={item} classes={classes}/>
            </Grid>
          ))}
        </Grid>
        {buttons && buttons.length > 0 && (
          <Box mt={10}>
            <Grid container justify="center">
              {buttons.map(button => (
                <Grid key={button.id} item xs={12} md={3}>
                  <ButtonElement
                    fullWidth
                    data={button}
                    variant="contained"
                    color="primary"
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Container>
    </div>
  );
}

function Employee({ item, classes }) {
  const url = getConsultantUrl(item.slug);

  return (
    <Card>
      <Link href={url}>
        <CardActionArea>
          {item.media && (
            <MediaElement
              isFluid
              data={item.media}
              alt={item.name}
              className={classes.cardImage}
            />
          )}
          <CardContent>
            <Box mb={1}>
              <Typography variant="subtitle1" component="h4">
                {item.name}
              </Typography>
              {item.position && (
                <Typography variant="subtitle2" component="h4">
                  {item.position}
                </Typography>
              )}
            </Box>
            <Box mt={3}>
              <Link href={url}>
                <Button
                  fullWidth
                  component="a"
                  startIcon={<FiInfo/>}
                >
                  Berater kennenlernen
                </Button>
              </Link>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  cardImage: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

StaffBlock.typeName = 'ComponentContentContentBlockStaff'; // Strapi element type
StaffBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
  }).isRequired
};
StaffBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  employees: yup.array().of(yup.object().shape({
    id: yup.number().required(),
    slug: yup.string().required(),
    name: yup.string().required(),
    position: yup.string().nullable(),
    media: MediaElement.dataSchema.nullable()
  })).required()
});
StaffBlock.graphQlSchema = `
... on ${StaffBlock.typeName} {
  id
  max_visible_items
  randomize_sort
  sort_override
  title {
    ${TypographyElement.graphQlSchema}
  }
  employees {
    id
    slug
    random_index
    name
    position
    media {
      ${MediaElement.graphQlSchema}
    }
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
}
`;
