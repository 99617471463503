import { ContentBlockRenderer, bootstrap } from '@plugins/next-cms-core';

import DefaultTemplate from '@components/templates/DefaultTemplate';
import Error from 'next/error';
import Head from 'next/head';
import PageInsetTop from '@components/atoms/PageInsetTop';
import PropTypes from 'prop-types';
import { components } from '../../cmsconfig';
import { gql } from '@apollo/client';
import { setUtmCampaign } from '@lib/utmCampaign';
import { siteName } from '../../constants';
import { useHideConsentOnPrivacyPage } from '@lib/gdpr';

/**
 * This is the entry point for the cms application. It takes an id
 * and fecthes the data from the database for the target Page.
 *
 */
// eslint-disable-next-line no-unused-vars
export default function CmdCoreAppIndex({ path, page }) {
  useHideConsentOnPrivacyPage([
    '/datenschutz',
    '/impressum'
  ]);

  if (!page) {
    return (
      <Error statusCode={404} title="Inhalt nicht gefunden" />
    );
  }

  if (!page.published_at) {
    return (
      <Error statusCode={410} title="Inhalt ist nicht mehr vorhanden" />
    );
  }

  if (path === 'athome-goodiebag') {
    setUtmCampaign('Immobilienscout24', 'Messe @home 03/2022');
  }

  const {
    robots,
    meta_page_title,
    meta_page_description,
    is_top_content_offset
  } = page;
  const componentData = {
    id: page.id,
    blocks: page.content ?? []
  };

  return (
    <>
      <Head>
        <title>
          {`${meta_page_title ?? ''} | ${siteName}`}
        </title>
        <meta name="description" content={meta_page_description} />
        {robots && robots !== 'index' && (
          <meta name="robots" content={robots} />
        )}
      </Head>
      <DefaultTemplate>
        {is_top_content_offset && <PageInsetTop />}
        <ContentBlockRenderer data={componentData} />
      </DefaultTemplate>
    </>
  );
}

CmdCoreAppIndex.propTypes = {
  path: PropTypes.string.isRequired,
  page: PropTypes.object
};
CmdCoreAppIndex.getInitialProps = async ({ query, res, graphQlClient }) => {
  const path = getPathFromQuery(query);

  try {
    const { data, error } = await graphQlClient.query({
      query: FETACH_PAGE,
      variables: { path }
    });

    if (error) {
      throw error;
    }

    if (!data.resolvePageByPath && res) {
      res.statusCode = 404;
    } else if (!data.resolvePageByPath.published_at && res) {
      res.statusCode = 410;
    }

    if (res && data.siteConfig.is_cache_enabled) {
      res.setHeader('Cache-Control', 'public, max-age=3600');
    }

    return {
      path,
      page: data.resolvePageByPath,
      isSidebarDisabled: data.resolvePageByPath?.is_sidebar_disabled,
      pageBottomConfig: {
        bottom_info_text: data.resolvePageByPath?.bottom_info_text
      }
    };
  }
  catch (error) {
    if (error.networkError?.result?.errors) {
      console.warn(JSON.stringify(error.networkError?.result?.errors, null, 2));
    }

    throw error;
  }
};

function getPathFromQuery(query) {
  if (!Array.isArray(query.path)) {
    return query.path;
  }

  return query.path.join('/');
}

const { graphQlFragments } = bootstrap({
  components
});

const FETACH_PAGE = gql`
query ResolvePageByPath($path: String!) {
  resolvePageByPath(path: $path) {
    id
    published_at
    name
    is_top_content_offset
    is_sidebar_disabled
    bottom_info_text
    meta_page_title
    meta_page_description
    robots
    content {
      ${graphQlFragments}
    }
  }
  siteConfig {
    is_cache_enabled
  }
}
`;
