import * as yup from 'yup';

import { Box, Grid } from '@material-ui/core';
import { ColorScheme, TypographyElement, useContentElement } from '@plugins/next-cms-core';

import Container from '../atoms/Container';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';

export default function TextBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TextBlock.dataSchema
  );

  const {
    textColumns,
    color_scheme
  } = elementData;

  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semantic_variant: 'h3',
    display_variant: 'h3'
  });

  const isSingleColumn = textColumns.length === 1;
  const columnSpread = Math.floor(12 / textColumns.length);
  const colorScheme = ColorScheme.getColorScheme(color_scheme, 'initial');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Grid container justify="center">
          <Grid item md={isSingleColumn ? 10 : 12} lg={isSingleColumn ? 9 : 12}>
            <Box mb={5} className={classes.titleContainer}>
              <TypographyElement
                data={title}
                color={colorScheme}
                className={classes.title}
              />
            </Box>
            <Grid
              container
              spacing={5}
              className={clsx(
                classes.textContainer,
                { [classes.textContainerSingle]: isSingleColumn }
              )}
            >
              {textColumns.map(element => {
                const text = omitBy(element, isNull);
                defaults(text, {
                  semantic_variant: 'div',
                  display_variant: 'body1',
                  text_align: 'justify'
                });

                return (
                  <Grid
                    item
                    key={element.id}
                    xs={12}
                    md={columnSpread}
                  >
                    <TypographyElement data={text} color={colorScheme} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  titleContainer: {},
  textContainerSingle: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9)
    }
  },
  title: {
    '& p': {
      margin: 0
    }
  }
}));

TextBlock.typeName = 'ComponentContentContentBlockText'; // Strapi element type
TextBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    textColumns: PropTypes.arrayOf(PropTypes.shape(TypographyElement.propTypes)),
    color_scheme: ColorScheme.propTypes
  }).isRequired
};
TextBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  textColumns: yup.array(TypographyElement.dataSchema),
  color_scheme: ColorScheme.dataSchema.nullable()
});
TextBlock.graphQlSchema = `
... on ${TextBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  textColumns: text {
    ${TypographyElement.graphQlSchema}
  }
  color_scheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
