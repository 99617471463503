import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
  },
  paper: {
    padding: theme.spacing(3)
  }
}));
